<template>
  <div
    class="modal fade"
    :id="`functionDebugModal`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`functionDebugModalLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5
            class="modal-title"
            :id="`functionDebugModalLabel`"
            v-if="functionData"
          >
            <i class="bi bi-filetype-json me-1"></i>
            {{ functionData.name }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="reset()"
          ></button>
        </div>
        <div class="modal-body" v-if="functionData">
          <form class="row form-debug-modal" novalidate>
            <div class="col-md-12">
              <div class="card">
                <div class="card-body pt-2 pb-2">
                  <div class="row mt-2">
                    <div class="col-md-4">
                      <label
                        class="form-label fs-4 text-primary text-decoration-none mb-2"
                        :href="`#/SetCode/Model/Edit/${functionData.functionInputModel.publicId}`"
                        target="_blank"
                        >{{
                          this.functionData.functionInputModel.formulaName
                        }}</label
                      >
                      <a
                        :href="`#/SetCode/Model/Edit/${functionData.functionInputModel.publicId}`"
                        target="_blank"
                        class="float-start fs-4 me-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        :title="
                          this.$t(
                            'SetCodeFunctions.RedirectUpdatePage',
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        "
                        ><i class="bi bi-link"></i
                      ></a>
                      <v-ace-editor
                        lang="csharp"
                        theme="monokai"
                        @init="editorInit"
                        :options="options"
                        style="height: 300px"
                        :value="functionData.functionInputModel.modelCode || ''"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="form-label fs-4 mb-2">{{
                        $t(
                          "SetCodeFunctions.FunctionCode",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <v-ace-editor
                        lang="vbscript"
                        theme="monokai"
                        @init="editorInit"
                        :options="options"
                        style="height: 300px"
                        :value="functionData.functionCode || ''"
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="form-label fs-4 mb-2">{{
                        $t(
                          "SetCodeFunctions.ExecutableFunctionCode",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <v-ace-editor
                        lang="csharp"
                        theme="monokai"
                        @init="executableFunctionCodeEditorInit"
                        :options="options"
                        style="height: 300px"
                        :value="functionData.executableFunctionCode || ''"
                      />
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-md-4">
                      <button
                        type="button"
                        class="btn btn-primary debug-modal-execute"
                        @click="clickExecuteButton('debug-modal-execute')"
                      >
                        <span>
                          {{
                            $t(
                              "Components.FunctionDebugModal.Execute",
                              {},
                              { locale: this.$store.state.activeLang }
                            )
                          }}</span
                        >
                      </button>
                    </div>
                  </div>
                  <div class="row mt-3" v-if="outputModelCode.isOk || errors">
                    <div class="col-md-6" v-if="outputModelCode.isOk">
                      <label class="form-label" style="font-size: large">{{
                        $t(
                          "Components.FunctionDebugModal.Output",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <json-viewer
                        :value="outputModelCode"
                        copyable
                        boxed
                        sort
                      />
                    </div>
                    <div class="col-md-6" v-if="errors">
                      <label class="form-label" style="font-size: large">{{
                        $t(
                          "Components.FunctionDebugModal.Error",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                      <json-viewer :value="errors" copyable boxed sort />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VAceEditor } from "vue3-ace-editor";
import JsonViewer from "vue-json-viewer";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/snippets/csharp";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-vbscript";
import "ace-builds/src-noconflict/snippets/vbscript";
import $ from "jquery";
export default {
  name: "FunctionDebugModal",
  components: {
    VAceEditor,
    JsonViewer,
  },
  watch: {
    refresh() {
      this.functionData = this.model;
    },
    "functionData.functionInputModel.modelCode": function (newValue) {
      console.log("Yeni modelCode değeri:", newValue);
      this.modelCode = newValue;
    },
  },
  props: {
    model: {
      type: Object,
      default() {
        return {};
      },
    },
    refresh: {
      type: String,
      default: "",
    },
  },
  emits: ["onDebugButtonClick"],
  data() {
    return {
      editorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableSnippets: true,
          enableLiveAutocompletion: true,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
      },
      executableFunctionCodeEditorInit: function (editor) {
        editor.setOptions({
          enableBasicAutocompletion: false,
          enableSnippets: false,
          enableLiveAutocompletion: false,
          autoScrollEditorIntoView: true,
        });
        editor.setShowPrintMargin(false);
        editor.setReadOnly(true);
      },
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        useWorker: true,
        wrap: true,
      },
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".debug-modal-execute",
      functionData: null,
      outputModelCode: {},
      errors: null,
      modelCodeData: "",
    };
  },
  methods: {
    clickExecuteButton(buttonName) {
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }
      this.reset();
      this.$prodGatewayAxios
        .post("/Brs-SetCodeFunctionExecute", this.model)
        .then((response) => {
          var result = response.data;
          if (result.isOk) {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.outputModelCode = {
              isOk: result.isOk,
              response: result.msg,
            };
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors = result;
          }
        })
        .catch(function (error) {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          alert(error);
        });
    },
    reset() {
      this.outputModelCode = {};
      this.errors = null;
    },
  },
  mounted() {},
};
</script>
